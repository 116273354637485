<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
  totalPage: { required: true, type: Number, default: 1 },
  active: { required: true, type: Number, default: 1 },
  perPage: { required: true, type: Number, default: 1 },
  elements: { required: true, type: Number, default: 1 },
  theme: { type: String, default: "blue" },
});
//const chevron_left = "<<<";
//const chevron_right = ">>>";
let from = 1;
let to = 1;
if (props.elements < props.perPage) {
  // eslint-disable-next-line vue/no-setup-props-destructure
  to = props.elements;
}
</script>

<template>
  <div class="pagination-content">
    <p v-if="elements > 0">
      Affichage de l’élément {{ from }} à {{ to }} sur {{ elements }} éléments
    </p>

    <ul v-if="totalPage > 1" class="page">
      <!-- <li class="page__btn">
        <span>{{ chevron_left }}</span>
      </li> -->
      <li
        v-for="(item, idx) in totalPage"
        :key="idx"
        @click="$emit('paginate', idx)"
        class="page__numbers"
        :class="active === item ? 'active' : null"
      >
        {{ item }}
      </li>
      <!-- <li class="page__dots">...</li> -->
      <!-- <li class="page__btn">
        <span> {{ chevron_right }} </span>
      </li> -->
    </ul>
  </div>
</template>

<style lang="scss">
:root {
  --primary: #23adad;
  --greyLight: #23adade1;
  --greyLight-2: #cbe0dd;
  --greyDark: #2d4848;
}

.pagination-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  //background: #ffffff;
  //box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
  p {
    font-size: 0.94rem;
  }
  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 0.6rem;

    &__numbers,
    &__btn,
    &__dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-inline: 0.2rem;
      font-size: 0.94rem;
      padding: 0.2rem 0.5rem;
      cursor: pointer;
    }

    &__dots {
      width: 2.6rem;
      height: 2.6rem;
      color: var(--greyLight);
      cursor: initial;
    }

    &__numbers {
      border-radius: 0.4rem;

      &:hover {
        color: var(--primary);
      }

      &.active {
        color: #ffffff;
        background: var(--primary);
        font-weight: 600;
        border: 1px solid var(--primary);
      }
    }

    &__btn {
      color: var(--greyLight);
      pointer-events: none;

      &.active {
        color: var(--greyDark);
        pointer-events: initial;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }
  ul {
    list-style-type: none;
    color: var(--greyDark);
  }
}
</style>