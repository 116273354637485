import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission.js";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

import {
  TextInput,
  PasswordInput,
  Select,
  Button,
  SearchInput,
  CardContrat,
  Loader,
  PanThumb,
  Modal,
  Pagination,
} from "./components/index";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "./styles/index.scss";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { size: "medium" })
  .component("TextInput", TextInput)
  .component("PasswordInput", PasswordInput)
  .component("Button", Button)
  .component("Select", Select)
  .component("SearchInput", SearchInput)
  .component("CardContrat", CardContrat)
  .component("Modal", Modal)
  .component("Loader", Loader)
  .component("PanThumb", PanThumb)
  .component("Pagination", Pagination)
  .component('QuillEditor', QuillEditor)
  .mount("#app");
