import { login, logout, getInfo } from "@/http/auth";
import { getToken, setToken, removeToken } from "@/helpers/auth";
import router from '../../router/index'
const state = () => ({
  access_token: getToken(),
  user: null,
  roles: [],
  loginStatus: "",
});

const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    setToken(token);
    state.access_token = token;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus;
  },
};

const actions = {
  // user login
  login(context, payload) {
    const { email, password } = payload;
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password })
        .then((response) => {
          if (!['Admin', 'Manager', 'Rédacteur'].includes(response.user.role)) {
            reject("Vous n'etes pas autorisé a vous connecter")
          } else {
            const { token } = response;
            context.commit("SET_ACCESS_TOKEN", token);
            context.commit("SET_ROLES", [response.user.role]);
            context.commit("SET_USER", response.user);
            context.commit("SET_LOGIN_STATUS", "success");
            resolve();
          }
        })
        .catch((error) => {
          context.commit("SET_LOGIN_STATUS", "failed");
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          if (!response) {
            reject("Verification failed, please Login again.");
          } else {
            const { role } = response;

            // roles must be a non-empty array
            if (!role || role.length <= 0) {
              reject("getInfo: roles must be a non-null array!");
            }
            Array.isArray(role)
              ? commit("SET_ROLES", role)
              : commit("SET_ROLES", Array(role));
            if (state.roles.includes("user")) dispatch("logout");
            commit("SET_USER", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      logout().then(() => {
        resolve();
      });
      setToken("");
      commit("SET_ROLES", []);
      removeToken();
      router.push({ path: '/connexion' });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_ROLES", []);
      removeToken();
      resolve();
    });
  },
};

const getters = {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  roles(state) {
    return state.roles;
  },
  isUserRole: (state) => (value) => {
    const result = state.roles.find((val) => val === value);
    if (result) {
      return true;
    } else {
      return false;
    }
  },
  token(state) {
    return state.access_token;
  },
  profile(state) {
    return state.user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
