<template>
  <Teleport to="body">
    <transition name="modal">
      <div class="modal_mask">
        <div class="modal_wrapper">
          <div class="modal_container">
            <div class="modal_header">
              <slot name="header"> default header </slot>
            </div>

            <div class="modal_body">
              <slot name="body"> default body </slot>
            </div>

            <div class="modal_footer">
              <slot name="footer">
                <button class="btn btn-primary" @click="$emit('close')">
                  Fermer
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn:hover {
  color: #2696be !important;
  border: 1px solid #2696be !important;
  background-color: transparent !important;
  transform: 0.5s all ease;
}

.modal_mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal_wrapper {
  display: table-cell;
  vertical-align: middle;
  padding-inline: 10px;
}

.modal_container {
  max-width: 50vw;
  max-height: calc(100vh - 150px);
  overflow: scroll;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  @media screen and (max-width: 992px) {
    max-width: 500px;
  }
  @media screen and (max-width: 576px) {
    max-width: 90vw;
  }
}

.modal_header,
.modal_footer {
  position: sticky;
}

.modal_body {
  margin: 20px 0;
}
.modal_footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
}
</style>

<style>
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
