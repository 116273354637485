import Cookies from 'js-cookie'

const TokenKey = '5a397110-1253-4f68-93d8-5a03f753c37a'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
