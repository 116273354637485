<template>
  <router-view />
</template>

<script setup>
import { onMounted } from "vue";

const SITE_KEY = "AIzaSyCizR_fqSijdNPUyo8wzs0fNkZaB2SMZ0o";

onMounted(() => {
  // load the script by passing the URL
  loadScriptByURL(
    "g-maps",
    `https://maps.googleapis.com/maps/api/js?key=${SITE_KEY}&libraries=places`,
  );
});
const loadScriptByURL = (id, url) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.setAttribute("async", true);
    script.setAttribute("defer", true);
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }

};
</script>
