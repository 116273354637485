import axios from "axios";
import store from "@/store";
import { getToken } from "../helpers/auth";
localStorage.setItem("BASE_URL", "https://api.love.findtechsarl.com");

const service = axios.create({
  baseURL: "https://api.love.findtechsarl.com/api/", // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  crossorigin: true,
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getters["user/token"]) {
      config.headers.common["Authorization"] = "Bearer " + getToken(); // Set JWT token
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    return res;
  },

  async (error) => {
    if (error.response?.status === 401) {
      const authData = store.getters["user/getAuthData"];
      const payload = {
        access_token: authData.token,
        refresh_token: authData.refreshToken,
      };

      var response = await axios.post(
        "http://localhost:3742/api/refreshToken",
        payload
      );
      store.commit("user/SET_AUTHDATA", response.data.data);
      error.config.headers[
        "Authorization"
      ] = `Bearer ${response.data.data.access_token}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
