<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref, reactive } from "vue";
import * as generalAPI from "@/http/general";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import moment from "@/helpers/moment";
import ClientModal from "./ClientModal.vue";
import { Search } from "@element-plus/icons-vue";

const { params } = useRoute();
const activeName = ref("users");
const openDialog = ref(false);
const loading = ref(false);
const tableData = ref([]);
const statistiques = ref(null);
const tabb = ref(null);
const clientData = ref();
const paginationData = reactive({
  lastPage: "",
  from: "",
  to: "",
  total: "",
  page: 1,
  perPage: 10,
  currentPage: "",
});
const requestQuery = reactive({
  name: null,
  certified: null,
  sex: null,
  certification_status: "",
});
const resetPaginationData = () => {
  paginationData.lastPage = "";
  paginationData.from = "";
  paginationData.to = "";
  paginationData.total = "";
  paginationData.page = 1;
  paginationData.perPage = 10;
  paginationData.currentPage = "";
};

onMounted(() => {
  switch (params.state) {
    case "pending":
      requestQuery.certification_status = "En attente";
      activeName.value = "pending";
      tabb.value = 0;
      break;

    case "certified":
      requestQuery.certification_status = "Acceptée";
      activeName.value = "certified";
      tabb.value = 1;
      break;

    default:
      break;
  }
  fetchData();
  fetchStats();
});

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await generalAPI.getClients(
      paginationData.page,
      paginationData.perPage,
      requestQuery
    );
    tableData.value = response.data;
    paginationData.lastPage = response.last_page;
    paginationData.from = response.from;
    paginationData.to = response.to;
    paginationData.total = response.total;
    paginationData.currentPage = response.current_page;
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les utilisateurs n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const fetchStats = async () => {
  loading.value = true;
  const response = await generalAPI.getStats();
  statistiques.value = response;
};

const handleTabClick = (tab) => {
  if (tab.props.name == "users") {
    requestQuery.certified = null;
    requestQuery.certification_status = "";
    tabb.value = null;
    resetPaginationData();
  } else if (tab.props.name == "pending") {
    requestQuery.certification_status = "En attente";
    tabb.value = 0;
    resetPaginationData();
  } else if (tab.props.name == "certified") {
    requestQuery.certification_status = "Acceptée";
    tabb.value = 1;
    resetPaginationData();
  }
  fetchData();
};

const handleDetailsModal = (item) => {
  clientData.value = item;
  openDialog.value = true;
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-uppercase">
          <a href="#">Findlove</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Utilisateurs ({{ statistiques?.customers ?? 0 }})
        </li>
      </ol>
    </nav>
    <el-input
      @change="fetchData"
      v-model="requestQuery.name"
      class="w-50 m-2"
      placeholder="Rechercher..."
      :prefix-icon="Search"
    />
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
    <el-tab-pane
      :label="'Utilisateurs(' + (statistiques?.customers ?? '') + ')'"
      name="users"
    >
    </el-tab-pane>
    <el-tab-pane
      :label="
        'En attente(' + (statistiques?.pendingCertifiedCustomers ?? '') + ')'
      "
      name="pending"
    >
    </el-tab-pane>
    <el-tab-pane
      :label="'Certifiés(' + (statistiques?.certifiedCustomers ?? '') + ')'"
      name="certified"
    >
    </el-tab-pane>
  </el-tabs>
  <div class="card py-3 border-0 table-responsive">
    <div>
      <el-select
        v-model="requestQuery.sex"
        @change="fetchData"
        class="m-2"
        placeholder="Selectionner un filtre"
        size="large"
      >
        <el-option label="Homme" value="M" />
        <el-option label="Femme" value="F" />
        <el-option label="Autre" value="X" />
      </el-select>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <!-- <th scope="col">No</th> -->
          <th scope="col">Date</th>
          <th scope="col">Pseudo</th>
          <th scope="col">Téléphone</th>
          <th scope="col">% Profil</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in tableData" :key="index">
          <tr
            :class="
              item?.certified && tabb !== 1 ? 'bg-success bg-opacity-25' : ''
            "
          >
            <!-- <td>{{ index + 1 }}</td> -->
            <td>
              {{ moment(item?.created_at).format("Do MMMM YYYY à HH:mm") }}
            </td>
            <td class="">
              <div>{{ item?.name }}</div>
              <small v-if="item?.certified" class="text-xs">
                Certifié par
                {{
                  item?.certified_by?.name ||
                  item?.user_certifications[item?.user_certifications.length]
                    ?.admin?.name
                }}
              </small>
            </td>
            <td>{{ item?.phone }}</td>
            <td>{{ item?.profile_completion }}</td>
            <td>
              <el-button @click="handleDetailsModal(item)"> Détails </el-button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <Loader :loading="loading" />
    <div class="d-flex justify-content-between align-items-center m-3">
      <ul class="pagination pointer">
        <li>
          <p
            @click="
              () => {
                if (paginationData.page > 1) {
                  paginationData.page--;
                  fetchData();
                }
              }
            "
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
          </p>
        </li>
        <template v-for="(page, index) in paginationData.lastPage" :key="index">
          <li :class="{ active: page === paginationData.currentPage }">
            <p
              @click="
                page !== paginationData.currentPage &&
                  ((paginationData.page = page), fetchData())
              "
            >
              {{ page }}
            </p>
          </li>
        </template>
        <li>
          <p
            @click="
              () => {
                if (paginationData.page < lastPage) {
                  paginationData.page++;
                  fetchData();
                }
              }
            "
            aria-label="Next"
          >
            <span aria-hidden="true">»</span>
          </p>
        </li>
      </ul>
      <div class="">
        <em
          >Affichage de {{ paginationData.from }} à {{ paginationData.to }} (sur
          {{ paginationData.total }} éléments)</em
        >
      </div>
    </div>
  </div>

  <ClientModal
    v-model:open="openDialog"
    :clientData="clientData"
    :certified="tabb"
    @onClose="(cb) => (cb ? fetchData() : null)"
  />
</template>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
}

.label {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(38, 150, 190, 0.2);
  color: rgb(38, 150, 190);
}

.text-xs {
  font-size: x-small;
}
</style>
