<script setup>
/* eslint-disable no-unused-vars */
import { defineProps, defineEmits, onMounted, ref } from "vue";
import * as usersAPI from "@/http/users";
import { giveSubscription, getSubscriptionDetails } from "@/http/subscription";
import { ElNotification } from "element-plus";
import { getImageUrl } from "@/http/general";

const props = defineProps({
  open: {
    required: true,
    type: Boolean,
  },
  showOnly: {
    type: Boolean,
    defaultValue: false,
  },
  clientData: {
    required: true,
  },
  certified: {
    required: false,
  },
});

const loading = ref(false);
const emit = defineEmits(["update:open", "onClose"]);
const openDialog = ref(false);
const openBlockDialog = ref(false);
const hasInteraction = ref(false);
const rejection_reason = ref();
const subscriptionOptions = ref(null);
const subscriptionId = ref(null);
const account_suspension_reason = ref(null);
const openSubscription = ref(false);

onMounted(() => {
  getSubscriptionDetails().then(
    (res) => (subscriptionOptions.value = res.data)
  );
});

const blockHandler = async () => {
  try {
    hasInteraction.value = true;
    loading.value = true;
    const data = {
      account_suspension_reason: account_suspension_reason.value,
    };
    await usersAPI.blockUser(props.clientData?.id, data);
    ElNotification({
      message: "Utilisateur bloqué !",
      type: "success",
    });
    closeModal();
  } catch (error) {
    ElNotification({
      message: "Utilisateur non bloqué, Veuillez réessayer !",
      type: "error",
    });
  } finally {
    loading.value = false;
    openBlockDialog.value = false;
    hasInteraction.value = false;
  }
};

const unblockHandler = async () => {
  try {
    hasInteraction.value = true;
    loading.value = true;
    await usersAPI.unblockUser(props.clientData?.id);
    ElNotification({
      message: "Utilisateur débloqué !",
      type: "success",
    });
    closeModal();
  } catch (error) {
    ElNotification({
      message: "Utilisateur non débloqué, veuillez réessayer !",
      type: "error",
    });
  } finally {
    loading.value = false;
    hasInteraction.value = false;
  }
};

const handleCertification = async () => {
  try {
    hasInteraction.value = true;
    loading.value = true;
    await usersAPI.certifyUser(props.clientData?.id);
    ElNotification({
      message: "Certification acceptée !",
      type: "success",
    });
    closeModal();
  } catch (error) {
    ElNotification({
      message: "Certification échouée, veuillez réessayer !",
      type: "error",
    });
  } finally {
    hasInteraction.value = false;
    loading.value = false;
  }
};

const handleRejectCertification = async () => {
  try {
    hasInteraction.value = true;
    loading.value = true;
    const data = {
      rejection_reason: rejection_reason.value,
    };
    await usersAPI.rejectUserCertification(
      props.clientData?.user_certifications[
        props.clientData?.user_certifications?.length - 1
      ]?.id,
      data
    );
    ElNotification({
      message: "Certification rejetée !",
      type: "success",
    });
    closeModal();
  } catch (error) {
    ElNotification({
      message: "Certification non rejetée, veuillez réessayer !",
      type: "error",
    });
  } finally {
    loading.value = false;
    hasInteraction.value = false;
    openDialog.value = false;
  }
};

const handleSubscription = async () => {
  try {
    hasInteraction.value = true;
    loading.value = true;
    const data = {
      invisible_mode: false,
      user_id: props.clientData.id,
    };
    await giveSubscription(subscriptionId.value, data);
    ElNotification({
      message: "Abonnement effectué !",
      type: "success",
    });
    closeModal();
  } catch (error) {
    ElNotification({
      message: "Erreur d'abonnement",
      type: "error",
    });
  } finally {
    hasInteraction.value = false;
    loading.value = false;
    openSubscription.value = false;
  }
};

const closeModal = () => {
  emit("onClose", hasInteraction.value);
  emit("update:open", false);
  rejection_reason.value = "";
};

const calculateAge = (dateOfBirth) => {
  if (dateOfBirth === null) {
    return;
  }
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age + " ans";
};
</script>

<template>
  <el-dialog
    :model-value="open"
    title="Détails"
    width="80vw"
    center
    :destroy-on-close="true"
    @close="closeModal"
  >
    <div class="row row-cols-3">
      <!-- <div>IS_CERTIFIED OR NOT{{ certified }}</div> -->
      <div id="picture" class="bg-light p-2 carousel slide">
        <div class="carousel-inner">
          <img
            v-if="clientData?.photos?.length === 0"
            src="@/assets/placeholder.jpg"
            class="mx-auto d-block"
            style="width: 100%"
            alt="Placeholder"
          />
          <div
            v-else
            v-for="(photo, index) in clientData.photos.slice(0, 3)"
            :key="photo?.id"
            class="carousel-item"
            :class="{ active: index === 0 }"
          >
            <img class="d-block w-100" :src="photo?.url" alt="photo" />
            <div class="carousel-caption">
              <h5 v-if="photo?.is_primary === 1" class="">Photo de profil</h5>
              <h5 v-else-if="photo?.is_primary === 0">Photo secondaire</h5>
            </div>
          </div>
          <div
            v-if="clientData?.user_certifications?.length > 0"
            class="carousel-item"
          >
            <img
              class="d-block w-100"
              :src="clientData?.user_certifications[0]?.url"
            />
            <div class="carousel-caption">
              <h5>Photo de vérification</h5>
            </div>
          </div>
          <div
            v-if="clientData?.user_certifications?.length > 0"
            class="carousel-item"
          >
            <img class="d-block w-100" :src="getImageUrl()" />
            <div class="carousel-caption">
              <h5>Photo de base</h5>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#picture"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#picture"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <ul class="p-2">
        <li>
          Age :
          {{ calculateAge(clientData?.birth_date) }}
        </li>
        <li>Sexe : {{ clientData?.sex }}</li>
        <li>
          Recherche :
          {{ clientData?.relation_type === "flirt" ? "Flirt" : null }}
          {{
            clientData?.relation_type === "serious" ? "Relation sérieuse" : null
          }}
        </li>
        <li>Ville de résidence : {{ clientData?.address }}</li>
        <li>Pays d'origine : {{ clientData?.origin }}</li>
        <li>Bio : {{ clientData?.bio }}</li>
        <li>
          Taille :
          {{ clientData?.height + (clientData?.height ? " cm" : null) }}
        </li>
        <li>
          Langue(s) :
          {{
            clientData?.languages
              ? clientData?.languages
                  .map((language) => language.name)
                  .join(", ")
              : null
          }}
        </li>
        <li>Niveau d'étude : {{ clientData?.education_level }}</li>
        <li>Profession : {{ clientData?.occupation }}</li>
      </ul>
      <ul class="p-2">
        <li>Religion : {{ clientData?.religion }}</li>
        <li>
          Enfants :
          {{ clientData?.children === true ? "Avec enfant(s)" : null }}
          {{ clientData?.children === false ? "Sans enfant(s)" : null }}
        </li>
        <li>Vis : {{ clientData?.housing_type }}</li>
        <li>Forme : {{ clientData?.shape }}</li>
        <li>Fume : {{ clientData?.smoke }}</li>
        <li>Bois : {{ clientData?.drink }}</li>
        <li>Lis : {{ clientData?.read }}</li>
      </ul>
    </div>
    <template #footer>
      <div v-if="showOnly != true">
        <Loader v-if="loading" />
        <div v-else class="dialog-footer">
          <div v-if="certified === 1">
            <el-button
              type="primary"
              class="m-2"
              @click="openSubscription = true"
            >
              Abonnement
            </el-button>
          </div>
          <div v-else-if="certified === 0">
            <el-popconfirm
              confirm-button-text="Oui"
              cancel-button-text="Non"
              icon-color="red"
              title="Certifier ce client"
              @confirm="handleCertification"
            >
              <template #reference>
                <el-button type="primary" class="m-2">Certifier</el-button>
              </template>
            </el-popconfirm>
            <template v-if="clientData?.is_active">
              <el-popconfirm
                confirm-button-text="Oui"
                cancel-button-text="Non"
                icon-color="red"
                title="Invalider la demande de certification"
                @confirm="openDialog = true"
              >
                <template #reference>
                  <el-button type="danger">Invalider</el-button>
                </template>
              </el-popconfirm>
            </template>
          </div>
          <div v-else>
            <el-popconfirm
              v-if="!clientData?.certified"
              confirm-button-text="Oui"
              cancel-button-text="Non"
              icon-color="red"
              title="Certifier ce client"
              @confirm="handleCertification"
            >
              <template #reference>
                <el-button type="primary" class="m-2">Certifier</el-button>
              </template>
            </el-popconfirm>
            <template v-if="clientData?.is_active">
              <el-popconfirm
                confirm-button-text="Oui"
                cancel-button-text="Non"
                icon-color="red"
                title="Bloquer ce client"
                @confirm="openBlockDialog = true"
              >
                <template #reference>
                  <el-button type="danger">Bloquer</el-button>
                </template>
              </el-popconfirm>
            </template>
            <template v-else>
              <el-popconfirm
                confirm-button-text="Oui"
                cancel-button-text="Non"
                icon-color="red"
                title="Débloquer ce client ?"
                @confirm="unblockHandler()"
              >
                <template #reference>
                  <el-button type="success">Débloquer</el-button>
                </template>
              </el-popconfirm>
            </template>
          </div>
        </div>
      </div>
    </template>
  </el-dialog>

  <el-dialog
    v-model="openDialog"
    title="Invalidation"
    center
    :destroy-on-close="true"
  >
    <label>Motif d'invalidation</label>
    <input type="text" v-model="rejection_reason" class="form-control" />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleRejectCertification">
          Valider
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="openBlockDialog"
    title="Invalidation"
    center
    :destroy-on-close="true"
  >
    <label>Motif de blocage</label>
    <input
      type="text"
      v-model="account_suspension_reason"
      class="form-control"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="blockHandler"> Valider </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="openSubscription"
    title="Abonnement"
    center
    :destroy-on-close="true"
  >
    <label>Abonnement</label>
    <el-select
      class="m-3"
      size="large"
      v-model="subscriptionId"
      placeholder="Choisir un abonnement"
    >
      <el-option
        v-for="subscription in subscriptionOptions"
        :key="subscription.id"
        :label="`Premium ${subscription.duration_in_months} mois`"
        :value="subscription.id"
      />
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleSubscription">
          Valider
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
input:focus {
  box-shadow: inherit !important;
}
li {
  font-size: 14px;
  margin: 10px;
}
ul {
  list-style-type: none;
}
</style>
