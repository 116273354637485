import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/login.vue";
import Layout from "@/layout/index";
import Clients from "@/views/clients/index.vue";
import Acceuil from "@/views/index.vue";
import NotFound from "@/views/notFound.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
export const constantRoutes = [
  {
    path: "/connexion",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "reset_password",
    component: ResetPassword,
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        component: Acceuil,
        name: "Acceuil",
      },
      {
        path: "reversements",
        component: () => import("@/views/reversements.vue"),
        name: "reversements",
      },
      {
        path: "articles",
        component: () => import("@/views/redacteur/index.vue"),
        name: "articles",
      },
      {
        path: "articles/create",
        component: () => import("@/views/redacteur/AddArticle.vue"),
        name: "addArticle",
      },
      {
        path: "articles/edit/:id",
        component: () => import("@/views/redacteur/AddArticle.vue"),
        name: "editArticle",
      },
      {
        path: "reviews",
        component: () => import("@/views/reviews/index.vue"),
        name: "reviews",
      },
      {
        path: "signalements",
        component: () => import("@/views/signalements/index.vue"),
        name: "signalements",
      },
      {
        path: "utilisateurs/:state?",
        component: Clients,
        name: "utilisateurs",
      },
      {
        path: "matching",
        component: () => import("@/views/matching/index.vue"),
        name: "Matching",
      },
      {
        path: "gestionnaires",
        component: () => import("@/views/gestionnaires/index.vue"),
        name: "Gestionnaires",
      },
      {
        path: "general",
        component: () => import("@/views/general.vue"),
        name: "general",
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

export const asyncRoutes = [];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
});
/* 
export function resetRouter() {
  const newRouter = router();
  router.matcher = newRouter.matcher;
} */
export default router;
