<script>
import { Icon } from '@iconify/vue';
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  components: {
    Icon
  },
  props: {
    minimal: {
      default: false,
      type: Boolean
    }
  },
  setup(){
    const store = useStore();
    const user = computed(() => store.getters["user/profile"]);
    return { 
      user
    }
  }
}
</script>

<template>
  <header class="navbar">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div v-if="minimal">
        <router-link to="/">
          <img alt="logo" src="@/assets/logo.png" height="50">
        </router-link>
      </div>
      <label v-else for="sidebar-btn-check">
        <Icon class="pointer ms-1" icon="ant-design:menu-fold-outlined" width="25" height="25"/>
      </label>
      <div class="d-flex justify-content-between align-items-center me-4">
        <span class="pointer">
          <Icon  icon="clarity:notification-line" width="30" height="30" />
        </span>
        <p class="mx-3 m-0 divider"></p>
        <div class="d-flex justify-content-start align-items-center">
          <!-- <div class="img_container me-2">
            <img src="@/assets/404.png" alt="" width="100%" srcset="">
          </div> -->
          <Icon icon="bxs:user-circle" width="35" height="35" class=""/>
          <div class="size-small">
            <router-link to="profil" class="d-block">{{ user?.name }}</router-link>
            <small class="d-block role">{{ user?.role }}</small>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style>

.navbar {
  height: 60px;
  width: 100%;
  background-color: #fcfcfc
}
.img_container {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  border: 1.5px solid #DFE0EB;
}
.role {
  color: #2696BE;
}
.divider {
  min-width: 1px;
  height: 20px;
  background: #DFE0EB;
}
.size-small {
  font-size: 13px;
}
</style>