import request from "./request";

export function get(page, perPage, filters) {
    let url = `dashboard/articles/written?page=${page}&perPage=${perPage}`;
    Object.keys(filters).forEach(key => {
        if (filters[key] !== null) {
            url += `&filter[${key}]=${filters[key]}`;
        }
    });
    return request({
        url,
        method: "get",
    });
}

export function getArticle(id) {
    return request({
        url: `dashboard/articles/written/${id}`,
        method: "get",
    });
}

export function setArticleImage(data) {
    return request({
        url: `dashboard/articles/images`,
        method: "post",
        data
    });
}

export function writtingStats() {
    return request({
        url: `dashboard/articles/written/stats`,
        method: "get",
    });
}

export function create(data) {
    return request({
        url: `dashboard/articles`,
        method: "post",
        data
    });
}

export function edit(id, data) {
    data.thumbnail === null ? delete data.thumbnail : null;
    return request({
        url: `dashboard/articles/${id}`,
        method: "post",
        data
    });
}

export function del(id) {
    return request({
        url: `dashboard/articles/${id}`,
        method: "delete"
    });
}