import request from "./request";

/**
 *
 * @param {Number} id
 * @returns
 */
export function giveSubscription(id, data) {
    return request({
        url: `dashboard/subscription-details/${id}/subscribe-user`,
        method: "put",
        data: data
    });
}

export function getSubscriptionDetails() {
    return request({
        url: "dashboard/subscription-details",
        method: "get",
    });
}

export function rejectUserCertification(id, data) {
    return request({
        url: "dashboard/user-certifications/" + id + "/reject",
        method: "post",
        data: data
    });
}

/**
 *
 * @param {Number} id
 * @returns
 */
export function unblockUser(id) {
    return request({
        url: "dashboard/users/" + id + "/unblock",
        method: "post",
    });
}

/**
 *
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} name
 * @returns
 */
export function getUsers(page, perPage, name, status = null, verified = null) {
    return request({
        url:
            `dashboard/users?page=${page}&perPage=${perPage}` +
            (name ? `&name=${name}` : "") +
            (status ? `&status=${status}` : "") +
            (verified ? `&verified=${verified}` : ""),
        method: "get",
    });
}

/**
 *
 * @param {Number} id
 * @returns
 */
export function getUser(id) {
    return request({
        url: "admin/users/" + id,
        method: "get",
    });
}
