<script>
import { ref, reactive, toRefs, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loading = ref(false);
    const redirect = ref(undefined);

    const state = reactive({
      loginForm: {
        email: "",
        password: "",
      },
      otherQuery: {},
    });

    //computed properties
    const getterLoginStatus = computed(
      () => store.getters["user/getLoginStatus"]
    );

    // methods
    const handleLogin = () => {
      loading.value = true;
      store
        .dispatch("user/login", state.loginForm)
        .then(() => {
          loading.value = false;
          if (getterLoginStatus.value === "success") {
            router.push({
              path: redirect.value || "/",
              query: state.otherQuery,
            });
          } else {
            ElNotification({
              message:
                "Vérifiez que tous les champs demandés sont bien remplis",
              type: "error",
            });
          }
        })
        .catch((e) => {
          if (typeof e == "string") {
            ElNotification({
              showClose: true,
              message: e,
              type: "error",
            });
          } else {
            ElNotification({
              message:
                "Vérifiez que tous les champs demandés sont bien remplis",
              type: "error",
            });
          }
          loading.value = false;
        });
    };
    const getOtherQuery = (query) => {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    };

    //watchers
    watch(
      route,
      () => {
        const query = route.query;
        if (query) {
          redirect.value = query.redirect;
          state.otherQuery = getOtherQuery(query);
        }
      },
      {
        immediate: true,
      }
    );

    return {
      handleLogin,
      getOtherQuery,
      ...toRefs(state),
      loading,
    };
  },
};
</script>

<template>
  <div
    class="d-flex justify-content-between flex-column"
    style="height: 100vh; overflow-x: hidden"
  >
    <div class="row row-cols-1 row-cols-md-2" style="height: 100vh">
      <div class="col leftWrapper">
        <router-link to="/" class="logo">
          <img src="@/assets/logo.png" alt="" srcset="" />
        </router-link>

        <div>
          <label for="" class="form-label">Email</label>
          <TextInput class="w-100" email v-model.trim="loginForm.email" />
        </div>
        <div>
          <label for="" class="form-label">Mot de passe</label>
          <PasswordInput v-model="loginForm.password" />
        </div>
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-lg-2 align-items-center"
        >
          <div class="col my-2">
            <Loader v-if="loading" />
            <Button v-else @click="handleLogin()">Connexion</Button>
          </div>
          <div class="col my-2">
            <router-link :to="{ name: 'forgot_password' }" class="fwpwd"
              >Mot de passe oublié?</router-link
            >
          </div>
        </div>
      </div>
      <div class="col d-none d-md-block">
        <div class="bg"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#text-white {
  color: #fff !important;
}
label {
  color: #20356c !important;
  margin-bottom: 0px !important;
}

.logo {
  text-align: center;
}

.logo img {
  width: 30%;
  margin-bottom: 50px;
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.leftWrapper > * {
  width: 80%;
}
.redirect {
  font-size: 13px;
}
.bg {
  height: 100%;
  background-image: url("../assets/auth_img.jpeg");
  background-size: cover;
  background-position: center;
}

.fwpwd {
  color: #ff1e1e;
  font-weight: 400;
}
</style>
