<script setup>
/* eslint-disable no-unused-vars */
import { ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElNotification } from "element-plus";
import { forgotPassword } from "@/http/auth";

const router = useRouter();
const loading = ref(false);
const redirect = ref(undefined);

const email = ref("");

const sendMail = async () => {
  loading.value = true;
  try {
    await forgotPassword({ email: email.value });
    router.push({
      path: redirect.value || "/reset-password",
    });
    ElNotification({
      message: "Vérifier vos mails pour récupérer votre code !",
      type: "success",
    });
  } catch (err) {
    ElNotification({
      message: "Une erreur est survenue. Veuillez réessayer",
      type: "error",
    });
    loading.value = false;
  }
};
</script>

<template>
  <div
    class="d-flex justify-content-between flex-column"
    style="height: 100vh; overflow-x: hidden"
  >
    <div class="row row-cols-1 row-cols-md-2" style="height: 100vh">
      <div class="col leftWrapper">
        <router-link to="/" class="logo">
          <img src="@/assets/logo.png" alt="" srcset="" />
        </router-link>

        <div>
          <label for="" class="form-label">Email</label>
          <TextInput class="w-100" email v-model.trim="email" />
        </div>
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-lg-2 align-items-center"
        >
          <div class="col my-2">
            <router-link to="/connexion" class="fwpwd"
              >Se connecter</router-link
            >
          </div>
          <div class="col my-2">
            <Loader v-if="loading" />
            <Button v-else @click="sendMail">Envoyer</Button>
          </div>
        </div>
        <!-- <div
          class="row row-cols-1 row-cols-sm-2 row-cols-lg-2 align-items-center"
        >
          <div class="col my-2">
            Pas de compte ?
            <router-link to="/signup">Créer un compte</router-link>
          </div>
        </div> -->
      </div>
      <div class="col d-none d-md-block">
        <div class="bg"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#text-white {
  color: #fff !important;
}
label {
  color: #20356c !important;
  margin-bottom: 0px !important;
}

.logo {
  text-align: center;
}

.logo img {
  width: 30%;
  margin-bottom: 50px;
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.leftWrapper > * {
  width: 80%;
}
.redirect {
  font-size: 13px;
}
.bg {
  height: 100%;
  background-image: url("../../assets/auth_img.jpeg");
  background-size: cover;
  background-position: center;
}

.fwpwd {
  color: #ff1e1e;
  font-weight: 400;
}
</style>
