import request from './request'

export function login(data) {
  return request({
    url: 'dashboard/login',
    method: 'post',
    data
  })
}

export function forgotPassword(data) {
  return request({
    url: 'dashboard/forgot-password',
    method: 'post',
    data
  })
}

export function resetPassword(data) {
  return request({
    url: 'dashboard/reset-password',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: 'profile',
    method: 'get'
  })
}


export function logout() {
  return request({
    url: 'logout',
    method: 'post'
  })
}

export function updatePassword(data) {
  return request({
    url: 'admin/password',
    method: 'put',
    data
  })
}