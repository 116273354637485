import request from "./request";

export function getStats() {
  return request({
    url: "dashboard/stats",
    method: "get",
  });
}

export function editSubscriptionDetails(id, data) {
  return request({
    url: `dashboard/subscription-details/${id}`,
    method: "put",
    data
  });
}

export function getNumberSelling() {
  return request({
    url: "dashboard/number-selling-options",
    method: "get"
  });
}

export function postNumberSelling() {
  return request({
    url: "dashboard/number-selling-options",
    method: "get",
  });
}

export function getCities() {
  return request({
    url: "dashboard/users/addresses",
    method: "get",
  });
}

export function getOrigins() {
  return request({
    url: "dashboard/users/origins",
    method: "get",
  });
}

export function getOccupations() {
  return request({
    url: "dashboard/users/occupations",
    method: "get",
  });
}

export function getLanguages() {
  return request({
    url: "dashboard/languages",
    method: "get",
  });
}

export function editNumberSelling(id, data) {
  return request({
    url: `dashboard/number-selling-options/${id}`,
    method: "put",
    data
  });
}

export function postCertificationImage(data) {
  return request({
    url: `dashboard/certification-image`,
    method: "post",
    data
  });
}

export function getClients(page, perPage, filters, age = null) {
  const { certification_status } = filters;
  delete filters.certification_status;
  let url = `dashboard/users?page=${page}&per_page=${perPage}`;

  if (certification_status != null) {
    url += `&certification_status=${certification_status}`;
  }

  if (age?.min_age != null) {
    url += `&min_age=${age.min_age}`;
  }

  if (age?.max_age != null) {
    url += `&max_age=${age.max_age}`;
  }

  Object.keys(filters).forEach(key => {
    if (filters[key] !== null) {
      url += `&filter[${key}]=${filters[key]}`;
    }
  });
  return request({
    url,
    method: "get",
  });
}

export function getReviews(page, perPage) {
  return request({
    url: `dashboard/reviews?page=${page}&perPage=${perPage}`,
    method: "get",
  });
}

export function getTrvlCost() {
  return request({
    url: "dashboard/travel-costs",
    method: "get",
  });
}

export function storeTrvlCost(data) {
  return request({
    url: "dashboard/travel-costs",
    method: "post",
    data,
  });
}

export function getVisites() {
  return request({
    url: "dashboard/visitor-stats/today",
    method: "get",
  });
}

export function getVisitesPerPeriode(start, end) {
  return request({
    url: `dashboard/visitor-stats?start=${start}&end=${end}`,
    method: "get",
  });
}

export function getSettings() {
  return request({
    url: `dashboard/settings`,
    method: "get",
  });
}

export function setSettings(id, data) {
  return request({
    url: `dashboard/settings/${id}`,
    method: "put",
    data
  });
}

export function getImageUrl() {
  const imageUrl =
    localStorage.getItem("BASE_URL") + "/storage/certification-image";
  const randomParam = Math.random().toString(36).substring(7);

  return `${imageUrl}?cache_bust=${randomParam}`;
}