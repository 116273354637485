<script setup>
import { Icon } from "@iconify/vue";
import { getStats } from "@/http/general";
import { writtingStats } from "@/http/article";
import { computed, onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";

const loading = ref(false);
const statistiques = ref();

onMounted(() => {
  fetchStats();
});

//make a drawerer javascript

const store = useStore();
const user = computed(() => store.getters["user/profile"]);

const fetchStats = async () => {
  try {
    if (user.value.role == "Rédacteur") {
      loading.value = true;
      const response = await writtingStats();
      statistiques.value = response;
    } else {
      loading.value = true;
      const response = await getStats();
      statistiques.value = response;
    }
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les statistiques n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

function formatDate(date) {
  if (!date) {
    return "";
  }

  const formattedDate = new Date(date);
  const jour = String(formattedDate.getDate()).padStart(2, "0");
  const mois = String(formattedDate.getMonth() + 1).padStart(2, "0");
  const annee = formattedDate.getFullYear();

  return `${jour}-${mois}-${annee}`;
}
</script>

<template>
  <div class="card border-0 p-4 mb-5">
    <div v-if="user.role === 'Rédacteur'">
      <div class="row row-cols-2 row-cols-md-4 mb-5">
        <div class="col">
          <p class="top-title text-nowrap">Clients</p>
          <div class="d-flex align-items-center">
            <div class="icon-wrapper" style="background: #fff500">
              <Icon icon="akar-icons:people-group" width="30" height="30" />
            </div>
            <h3 class="mx-3 fs-4">{{ statistiques?.customers }}</h3>
          </div>
        </div>
        <div class="col">
          <p class="top-title text-nowrap">Ventes</p>
          <div class="d-flex align-items-center">
            <div class="icon-wrapper" style="background: #23855f">
              <Icon icon="akar-icons:cart" width="30" height="30" />
            </div>
            <h3 class="mx-3 fs-4">{{ statistiques?.sales }}</h3>
          </div>
        </div>
        <div class="col">
          <router-link :to="{ name: 'articles' }">
            <p class="top-title text-nowrap">Articles publiés</p>
            <div class="d-flex align-items-center">
              <div class="icon-wrapper" style="background: #2696be">
                <Icon
                  icon="akar-icons:shipping-box-v1"
                  width="30"
                  height="30"
                />
              </div>
              <h3 class="mx-3 fs-4">{{ statistiques?.articles_published }}</h3>
            </div>
          </router-link>
        </div>
        <div class="col">
          <div>
            <p class="top-title text-nowrap">Balance</p>
          </div>
          <div class="d-flex align-items-center">
            <div class="icon-wrapper" style="background: #ff0505">
              <Icon icon="ph:money" width="30" height="30" />
            </div>
            <h3 class="mx-3 text-nowrap">
              {{ statistiques?.balance.toFixed(2) }} FCFA
            </h3>
          </div>
          <!--  <small>
            Commission: {{ statistiques?.real_revenue?.toFixed(2) }} FCFA
          </small> -->
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 fs-6">
        <div class="col">
          Chiffre d'affaires depuis le {{ formatDate(user?.created_at) }} :
          {{ statistiques?.revenue.toFixed(2) }}
          FCFA
        </div>
        <div class="col">
          Commission gagnée depuis le {{ formatDate(user?.created_at) }} :
          {{ statistiques?.commission.toFixed(2) }}
          FCFA
        </div>
      </div>
    </div>
    <div v-else class="d-flex flex-wrap justify-content-between mb-5">
      <div class="w-auto">
        <router-link :to="{ name: 'utilisateurs' }">
          <p class="top-title text-nowrap">Utilisateurs</p>
          <div class="d-flex align-items-center">
            <div class="icon-wrapper" style="background: #fff500">
              <Icon icon="akar-icons:people-group" width="30" height="30" />
            </div>
            <h3 class="mx-3 fs-4">{{ statistiques?.customers }}</h3>
          </div>
        </router-link>
      </div>
      <div class="w-auto">
        <router-link
          :to="{ name: 'utilisateurs', params: { state: 'pending' } }"
        >
          <p class="top-title text-nowrap">En attente</p>
          <div class="d-flex align-items-center">
            <div class="icon-wrapper" style="background: #23855f">
              <Icon icon="akar-icons:people-group" width="30" height="30" />
            </div>
            <h3 class="mx-3 fs-4">
              {{ statistiques?.pendingCertifiedCustomers }}
            </h3>
          </div>
        </router-link>
      </div>
      <div class="w-auto">
        <router-link
          :to="{ name: 'utilisateurs', params: { state: 'certified' } }"
        >
          <p class="top-title text-nowrap">Certifiés</p>
          <div class="d-flex align-items-center">
            <div class="icon-wrapper" style="background: #2696be">
              <Icon icon="akar-icons:people-group" width="30" height="30" />
            </div>
            <h3 class="mx-3 fs-4">{{ statistiques?.certifiedCustomers }}</h3>
          </div>
        </router-link>
      </div>
      <div v-if="user.role === 'Admin'" class="w-auto">
        <div>
          <p class="top-title text-nowrap">Balance</p>
        </div>
        <div class="d-flex align-items-center">
          <div class="icon-wrapper" style="background: #ff0505">
            <Icon icon="ph:money" width="30" height="30" />
          </div>
          <h3 class="mx-3 text-nowrap fs-4">
            {{ statistiques?.revenue.toFixed(2) }} FCFA
          </h3>
        </div>
        <small>
          Findlove: {{ statistiques?.commissionRevenue.toFixed(2) }} FCFA
        </small>
      </div>
    </div>
  </div>
  <Loader :loading="loading" title="Chargement en cours" />
</template>

<style lang="scss" scoped>
.icon-wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.top-title {
  color: #7a7777;
  margin-left: 60px;
}

* {
  border: 0.1 solid red;
}
</style>
