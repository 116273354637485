import request from "./request";

/**
 *
 * @param {Number} id
 * @returns
 */
export function blockUser(id, data) {
  return request({
    url: "dashboard/users/" + id + "/block",
    method: "post",
    data: data
  });
}

export function certifyUser(id) {
  return request({
    url: "dashboard/users/" + id + "/certify",
    method: "post",
  });
}

export function rejectUserCertification(id, data) {
  return request({
    url: "dashboard/users-certifications/" + id + "/reject",
    method: "post",
    data: data
  });
}

/**
 *
 * @param {Number} id
 * @returns
 */
export function unblockUser(id) {
  return request({
    url: "dashboard/users/" + id + "/unblock",
    method: "post",
  });
}

/**
 *
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} name
 * @returns
 */
export function getUsers(page, perPage, name, status = null, verified = null) {
  return request({
    url:
      `dashboard/users?page=${page}&perPage=${perPage}` +
      (name ? `&name=${name}` : "") +
      (status ? `&status=${status}` : "") +
      (verified ? `&verified=${verified}` : ""),
    method: "get",
  });
}

/**
 *
 * @param {Number} id
 * @returns
 */
export function getUser(id) {
  return request({
    url: "admin/users/" + id,
    method: "get",
  });
}
